import { Component, OnDestroy } from '@angular/core';
import { Subscription }   from 'rxjs';
import {NavbarService} from '../../layout/header/navbar.service'
import { ModalsService} from './modals.service'
import {Location} from '@angular/common';
import {Router, ActivatedRoute} from '@angular/router';
import { CandidateService } from '../../routes/shared/candidate/candidate.service';
import { JobsService } from '../../routes/jobs/jobs.service';

import {AuthenticationService } from '../../core/authentication/authentication.service';
import {ViewChild, Input} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../../core/shared/shared.service';


@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss']
})
export class ModalsComponent implements OnDestroy {
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: true
  };
  @ViewChild('candidateModal',{static: false}) public candidateModal:ModalDirective;
  @ViewChild('searchModal',{static: false}) public searchModal:ModalDirective;
  @ViewChild('createJobModal',{static: false}) public createJobModal:ModalDirective;
  @ViewChild('createClientModal',{static: false}) public createClientModal:ModalDirective;
  @ViewChild('inviteUsersModal',{static: false}) public inviteUsersModal:ModalDirective;
  @ViewChild('editJobModal',{static: false}) public editJobModal:ModalDirective;
  @ViewChild('configureEmailModal',{static: false}) public configureEmailModal:ModalDirective;
  @ViewChild('configureSendOnlyEmailModal',{static: false}) public configureSendOnlyEmailModal:ModalDirective;
  @ViewChild('emailModal',{static: false}) public emailModal:ModalDirective;
  @ViewChild('manageCollaboratorsModal',{static: false}) public manageCollaboratorsModal:ModalDirective;
  @ViewChild('uploadModal',{static: false}) public uploadModal:ModalDirective;
  @ViewChild('shareCandidateModal',{static: false}) public shareCandidateModal:ModalDirective;
  @ViewChild('notificationsModal',{static: false}) public notificationsModal:ModalDirective;
  @ViewChild('transferToJobModal',{static: false}) public transferToJobModal:ModalDirective;
  @ViewChild('addTagsModal',{static: false}) public addTagsModal:ModalDirective;
  @ViewChild('manageTagsModal',{static: false}) public manageTagsModal:ModalDirective;
  @ViewChild('downloadCsvModal',{static: false}) public downloadCsvModal:ModalDirective;
  @ViewChild('exportStandardizedTemplateModal',{static: false}) public exportStandardizedTemplateModal:ModalDirective;
  @ViewChild('keywordRankModal',{static: false}) public keywordRankModal:ModalDirective;
  @ViewChild('massUpdateModal',{static: false}) public massUpdateModal:ModalDirective;
  @ViewChild('sourceFromVendorModal',{static: false}) public sourceFromVendorModal:ModalDirective;
  @ViewChild('jobsForVendorModal',{static: false}) public jobsForVendorModal:ModalDirective;
  @ViewChild('showAppointmentModal',{static: false}) public showAppointmentModal:ModalDirective;
  @ViewChild('notificationAlertModal',{static: false}) public notificationAlertModal:ModalDirective;
  @ViewChild('candidateCollaboratorsModal',{static: false}) public candidateCollaboratorsModal:ModalDirective;
  @ViewChild('savedSearchModal',{static: false}) public savedSearchModal:ModalDirective;
  @ViewChild('topUpModal',{static: false}) public topUpModal:ModalDirective;
  @ViewChild('spinnerModal',{static: false}) public spinnerModal:ModalDirective;
  @ViewChild('jobApplicationModal',{static: false}) public jobApplicationModal:ModalDirective;
  @ViewChild('customTrackerModal',{static: false}) public customTrackerModal:ModalDirective;
  @ViewChild('standardizedTemplateModal',{static: false}) public standardizedTemplateModal:ModalDirective;
  @ViewChild('upgradePlanModal',{static: false}) public upgradePlanModal:ModalDirective;
  @ViewChild('editBannerModal',{static: false}) public editBannerModal:ModalDirective;
  @ViewChild('editLogoModal',{static: false}) public editLogoModal:ModalDirective;
  @ViewChild('editAboutCompanyModal',{static: false}) public editAboutCompanyModal:ModalDirective;
  @ViewChild('careerSettingsModal',{static: false}) public careerSettingsModal:ModalDirective;
  @ViewChild('addJobsToCareerPageModal',{static: false}) public addJobsToCareerPageModal:ModalDirective;
  @ViewChild('tobuDriveStatsModal',{static: false}) public tobuDriveStatsModal:ModalDirective;
  @ViewChild('autoForwardingModal',{static: false}) public autoForwardingModal:ModalDirective;
  @ViewChild('adminUpdatePricingPlanModal',{static: false}) public adminUpdatePricingPlanModal:ModalDirective;
  @ViewChild('createTagModal',{static: false}) public createTagModal:ModalDirective;
  @ViewChild('helpModal',{static: false}) public helpModal:ModalDirective;
  @ViewChild('autoResponseModal',{static: false}) public autoResponseModal:ModalDirective;
  @ViewChild('exportAppointmentsModal',{static: false}) public exportAppointmentsModal:ModalDirective;
  @ViewChild('leadCardModal',{static: false}) public leadCardModal:ModalDirective;
  @ViewChild('accessLevelsModal',{static: false}) public accessLevelsModal:ModalDirective;
  @ViewChild('editStagesModal',{static: false}) public editStagesModal:ModalDirective;
  @ViewChild('disabledUserModal',{static: false}) public disabledUserModal:ModalDirective;
  @ViewChild('customizeColumnOrderModal',{static: false}) public customizeColumnOrderModal:ModalDirective;
  // @Input() title:string;
  subscription: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;
  subscription4: Subscription;
  subscription5: Subscription;
  // big_modal_selected: string = '';
  // medium_modal_selected: string = '';
  // small_modal_selected: string = '';
  current_page: any = {};
  params: any = {};
  page_params: any = {};
  hideCandidateModal = true;
  hideSearchModal = true;
  hideCreateJobModal = true;
  hideCreateClientModal = true;
  hideEditJobModal = true;
  hideEmailModal = true;
  hideTransferToJobModal = true;
  hideShareCandidateModal = true;
  hideAddTagsModal = true;
  hideManageTagsModal = true;
  hideDownloadCsvModal = true;
  hideExportStandardizedTemplateModal = true;
  hideKeywordRankModal = true; 
  hideMassUpdateModal = true;
  hideSourceFromVendorModal = true;
  hideManageCollaboratorsModal= true;
  hideUploadModal = true;
  hideJobsForVendorModal = true;
  hideInviteUsersModal= true;
  hideShowAppointmentModal = true;
  hideNotificationsModal = true;
  hideNotificationAlertModal =true;
  hideCandidateCollaboratorsModal = true;
  hideConfigureEmailModal = true;
  hideConfigureSendOnlyEmailModal = true;
  hidespinnerModal = true;
  hideSavedSearchModal = true;
  hideTopUpModal = true;
  hideCustomTrackerModal = true;
  hideStandardizedTemplateModal = true;
  hideUpgradePlanModal = true;
  hideEditBannerModal = true;
  hideEditLogoModal = true;
  hideEditAboutCompanyModal = true;
  hideCareerSettingsModal = true;
  hideAddJobsToCareerPageModal = true;
  hideTobuDriveStatsModal = true;
  hideAutoForwardingModal = true;
  hideAdminUpdatePricingPlanModal = true;
  hideCreateTagModal=true;
  hideHelpModal=true;
  hideAutoResponseModal = true;
  hideExportAppointmentsModal = true;
  hideLeadCardModal = true;
  hideAccessLevelsModal = true;
  hideEditStagesModal=true;
  hideDisabledUserModal=true;
  hideCustomizeColumnOrderModal = true;
  sub2:Subscription;
  page_selected='entire_db';
  is_resume_view_default= false;
  sub3: Subscription;
  // hideJobApplicationModal = true;
  // ar= new Array(1000);
  configure_desktop_chosen=false;
  is_recruiter_account = true;
  is_job_integration_enabled=false;
  is_email_blast_integration_enabled = false;
  comment_count=''
  prev_result=''
  next_result=''
  has_ip_restriction = false
  is_account_active = true
  is_ats_user=false


  constructor(
    private modal: ModalsService,
    private navbar: NavbarService,
    private _location: Location,
    private router:Router,
    private candidate: CandidateService,
    private job:JobsService,
    private authService: AuthenticationService,
    private shared: SharedService,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
  	// console.log('checkpoint1');
    this.load_configure_email();
    this.authService.post('/api/get_access_rights_api', {}).subscribe(
      res=> {
        console.log(res);
        this.is_job_integration_enabled = res['rcar']['is_job_integration_enabled']
        this.is_email_blast_integration_enabled=res['rcar']['is_email_blast_integration_enabled'];
        this.is_ats_user = res['rcar']['is_ats_user'];
      },
      err=> console.log(err)
      )
    // this.sub2 = this.route.queryParams.subscribe(
    //   params=> {
    //     console.log(params);
    //     // if(params['search_type']==='email_search'&&params['spam_flag']==='false'){this.page_selected='resume_emails'}
    //     // else if(params['search_type']==='email_search'&&params['smart_filter_job_descriptions']==='true'){this.page_selected='job_descriptions'}
    //     // else if(params['search_type']==='email_search'&&params['smart_filter_other_documents']==='true'){this.page_selected='other_documents'}
    //     // else if(params['search_type']==='entire_database'&&params['method_of_upload']==='Tobu Drive'){this.page_selected='tobu_drive'}
    //     // else if(params['search_type']==='entire_database'&&params['method_of_upload']==='Web Upload'){this.page_selected='web_upload'}
    //     // else if(params['search_type']==='entire_database'&&params['method_of_upload']==='Career Page'){this.page_selected='career_page'}
    //     // else{this.page_selected='entire_db'}
    //     if(params['method_of_upload']==='Email' && params['type_of_document'] ==="Resume"){this.page_selected='resume_emails'}
    //     else if(params['method_of_upload']==='Email' && params['type_of_document'] ==="Job Description"){this.page_selected='job_descriptions'}
    //     else if(params['method_of_upload']==='Email' && params['type_of_document'] ==="Other Documents"){this.page_selected='other_documents'}
    //     else if(params['method_of_upload']==='Tobu Drive'){this.page_selected='tobu_drive'}
    //     else if(params['method_of_upload']==='Web Upload'){this.page_selected='web_upload'}
    //     else if(params['method_of_upload']==='Career Page'){this.page_selected='career_page'}
    //     else{this.page_selected='entire_db'}
    //   }
    // ) 
    this.sub3 = this.navbar.type_of_document$.subscribe(
      res=> {
        console.log(res);
        if (res['type_of_document']==="Job Description"){ this.page_selected = 'job_descriptions'; this.navigate_to('resume')}
          else if (res['type_of_document']==="Other Documents"){ this.page_selected = 'other_documents'; this.navigate_to('resume')}
            else {this.page_selected='entire_db'}
        console.log(this.page_selected)

    })
  	// this.subscription = this.modal.big_modal_selected$.subscribe(
   //      res => {

   //          this.big_modal_selected = res["name"]; 
   //          console.log('modal selected');
   //          console.log(this.big_modal_selected);
   //          this.params = res["params"]
   //          console.log('params');
   //          console.log(this.params);
   //          // this.hideCandidateModal = this.big_modal_selected!='candidate';
   //          // this.hideSearchModal = this.big_modal_selected!='search';
   //          // this.hideCreateJobModal = this.big_modal_selected!='create_job';
   //          // this.hideEditJobModal = this.big_modal_selected!='edit_job';
   //          // this.hideInviteUsersModal = this.big_modal_selected!='invite_users';
   //          // this.hideConfigureEmailModal = this.big_modal_selected!='configure_email';
   //          // if(this.big_modal_selected==='candidate'){
   //          //   this.candidateModal.show();
   //          // }
   //          // else{
   //          //   this.candidateModal.hide();
   //          // }
   //          if(this.big_modal_selected==='configure_email'){
   //            this.configureEmailModal.show();
   //          }
   //          // else{
   //          //   this.configureEmailModal.hide();
   //          // }
   //          if(this.big_modal_selected==='search'){
   //            this.searchModal.show();
   //          }
   //          else{this.searchModal.hide();}
   //          if(this.big_modal_selected==='create_job'){
   //            this.createJobModal.show();
   //          }
   //          else{
   //            this.createJobModal.hide();
   //          }
   //          if(this.big_modal_selected==='edit_job'){
   //            this.editJobModal.show();
   //          }
   //          else{
   //            this.editJobModal.hide();
   //          }
   //          if(this.big_modal_selected==='invite_users'){
   //            this.inviteUsersModal.show();
   //          }
   //          else{
   //            this.inviteUsersModal.hide();
   //          }
   //      },
   //      err => {console.log(err)}
   //  );
    // console.log(this.navbar.current_page$);
    this.subscription2 = this.navbar.current_page$.subscribe(
      res => {
        // console.log(res);
        this.current_page = res;
    });
    this.modal.get_resume_details_and_count().subscribe(
      res=>{
        console.log(res)
        this.prev_result=res['prev_result']
        this.next_result=res['next_result']
        this.comment_count=res['comment_count']
      }
      )
    // this.subscription4 = this.modal.medium_modal_selected$.subscribe(
    //     res => {

    //         this.medium_modal_selected = res["name"]; 
    //         console.log('modal selected');
    //         console.log(this.medium_modal_selected);
    //         this.params = res["params"]
    //         console.log('params');
    //         console.log(this.params);
    //         this.hideEmailModal = this.medium_modal_selected!='email';
    //         this.hideManageCollaboratorsModal = this.medium_modal_selected!='manage_collaborators';
    //         this.hideUploadModal = this.medium_modal_selected!='upload';
    //         this.hideShareCandidateModal = this.medium_modal_selected!='share_candidate';
    //         this.hideNotificationsModal = this.medium_modal_selected!='notifications';
    //         if(this.medium_modal_selected==='email'){
    //           this.candidateModal.show();
    //         }
    //         if(this.medium_modal_selected==='manage_collaborators'){
    //           this.candidateModal.show();
    //         }
    //         if(this.medium_modal_selected==='upload'){
    //           this.candidateModal.show();
    //         }
    //         if(this.medium_modal_selected==='share_candidate'){
    //           this.candidateModal.show();
    //         }
    //         if(this.medium_modal_selected==='notifications'){
    //           this.candidateModal.show();
    //         }
    //     },
    //     err => {console.log(err)}
    // );
    // this.subscription3 = this.modal.small_modal_selected$.subscribe(
    //     res => {

    //         this.small_modal_selected = res["name"]; 
    //         console.log('modal selected');
    //         console.log(this.small_modal_selected);
    //         this.params = res["params"]
    //         console.log('params');
    //         console.log(this.params);
    //         this.hideTransferToJobModal = this.small_modal_selected!='transfer_to_job';
    //         this.hideAddTagsModal = this.small_modal_selected!='add_tags';
    //         this.hideDownloadCsvModal = this.small_modal_selected!='download_csv';
    //         this.hideKeywordRankModal = this.small_modal_selected!='keyword_rank';
    //         this.hideMassUpdateModal = this.small_modal_selected!='mass_update';
    //         this.hideSourceFromVendorModal = this.small_modal_selected!='source_from_vendor';
    //         this.hideJobsForVendorModal = this.small_modal_selected!='jobs_for_vendor';
    //         this.hideShowAppointmentModal = this.small_modal_selected!='show_appointment';
    //         this.hideNotificationAlertModal = this.small_modal_selected!='notification_alert';
    //         this.hideCandidateCollaboratorsModal = this.small_modal_selected!='candidate_collaborators';
    //         if(this.small_modal_selected==='transfer_to_job'){
    //           this.candidateModal.show();
    //         }
    //         if(this.small_modal_selected==='add_tags'){
    //           this.candidateModal.show();
    //         }
    //         if(this.small_modal_selected==='download_csv'){
    //           this.candidateModal.show();
    //         }
    //         if(this.small_modal_selected==='keyword_rank'){
    //           this.candidateModal.show();
    //         }
    //         if(this.small_modal_selected==='mass_update'){
    //           this.candidateModal.show();
    //         }
    //         if(this.small_modal_selected==='source_from_vendor'){
    //           this.candidateModal.show();
    //         }
    //         if(this.small_modal_selected==='jobs_for_vendor'){
    //           this.candidateModal.show();
    //         }
    //         if(this.small_modal_selected==='show_appointment'){
    //           this.candidateModal.show();
    //         }
    //         if(this.small_modal_selected==='notification_alert'){
    //           this.candidateModal.show();
    //         }
    //         if(this.small_modal_selected==='candidate_collaborators'){
    //           this.candidateModal.show();
    //         }

    //     },
    //     err => {console.log(err)}
    // );
    this.modal.candidate_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideCandidateModal = false;
            this.candidateModal.show();
            var params1 = this.candidate.get_params_from_url(this.router.url);
            var params2 = this.job.get_params_from_url(this.router.url);
            this.page_params= this.shared.merge_options(params1, params2);
            // console.warn(this.page_params);
            
          }
          if(res['state']==='hidden'){
            this.hideCandidateModal = true;
            // console.log(this.hideCandidateModal)
            this.candidateModal.hide();
            
          }
          this.params = res["params"]
        }
    )
    this.modal.search_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideSearchModal=false;
            this.searchModal.show();
          }
          if(res['state']==='hidden'){
            this.hideSearchModal=true;
            this.searchModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.configure_email_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideConfigureEmailModal = false;
            this.configureEmailModal.show();
          }
          if(res['state']==='hidden'){
            this.hideConfigureEmailModal = true;
            this.configureEmailModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.configure_send_only_email_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideConfigureSendOnlyEmailModal = false;
            this.configureSendOnlyEmailModal.show();
          }
          if(res['state']==='hidden'){
            this.hideConfigureSendOnlyEmailModal = true;
            this.configureSendOnlyEmailModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.create_job_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideCreateJobModal=false;
            this.createJobModal.show();
          }
          if(res['state']==='hidden'){
            this.hideCreateJobModal=true;
            this.createJobModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.create_client_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideCreateClientModal=false;
            this.createClientModal.show();
          }
          if(res['state']==='hidden'){
            this.hideCreateClientModal=true;
            this.createClientModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.edit_job_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideEditJobModal=false;
            this.editJobModal.show();
          }
          if(res['state']==='hidden'){
            this.hideEditJobModal=true;
            this.editJobModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.invite_users_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideInviteUsersModal=false;
            this.inviteUsersModal.show();
          }
          if(res['state']==='hidden'){
            this.hideInviteUsersModal=true;
            this.inviteUsersModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.email_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideEmailModal=false;
            this.emailModal.show();
          }
          if(res['state']==='hidden'){
            this.hideEmailModal=true;
            this.emailModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.manage_collaborators_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideManageCollaboratorsModal=false;
            this.manageCollaboratorsModal.show();
          }
          if(res['state']==='hidden'){
            this.hideManageCollaboratorsModal=true;
            this.manageCollaboratorsModal.hide();
          }
          this.params = res["params"]
        }
    )

    this.modal.upload_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideUploadModal=false;
            this.uploadModal.show();
          }
          if(res['state']==='hidden'){
            this.hideUploadModal=true;
            this.uploadModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.share_candidate_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideShareCandidateModal=false;
            this.shareCandidateModal.show();
          }
          if(res['state']==='hidden'){
            this.hideShareCandidateModal=true;
            this.shareCandidateModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.notifications_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideNotificationsModal=false;
            this.notificationsModal.show();
          }
          if(res['state']==='hidden'){
             this.hideNotificationsModal=true;
            this.notificationsModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.transfer_to_job_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideTransferToJobModal=false;
            this.transferToJobModal.show();
          }
          if(res['state']==='hidden'){
            this.hideTransferToJobModal=true;
            this.transferToJobModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.add_tags_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideAddTagsModal=false;
            this.addTagsModal.show();
          }
          if(res['state']==='hidden'){
            this.hideAddTagsModal=true;
            this.addTagsModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.manage_tags_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideManageTagsModal=false;
            this.manageTagsModal.show();
          }
          if(res['state']==='hidden'){
            this.hideManageTagsModal=true;
            this.manageTagsModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.download_csv_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideDownloadCsvModal=false;
            this.downloadCsvModal.show();
          }
          if(res['state']==='hidden'){
            this.hideDownloadCsvModal=true;
            this.downloadCsvModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.export_standardized_template_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideExportStandardizedTemplateModal=false;
            this.exportStandardizedTemplateModal.show();
          }
          if(res['state']==='hidden'){
            this.hideExportStandardizedTemplateModal=true;
            this.exportStandardizedTemplateModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.keyword_rank_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideKeywordRankModal=false;
            this.keywordRankModal.show();
          }
          if(res['state']==='hidden'){
            this.hideKeywordRankModal=true;
            this.keywordRankModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.mass_update_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideMassUpdateModal=false;
            this.massUpdateModal.show();
          }
          if(res['state']==='hidden'){
            this.hideMassUpdateModal=true;
            this.massUpdateModal.hide();
          }
          this.params = res["params"]
        }
    )

    this.modal.source_from_vendor_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideSourceFromVendorModal=false;
            this.sourceFromVendorModal.show();
          }
          if(res['state']==='hidden'){
            this.hideSourceFromVendorModal=true;
            this.sourceFromVendorModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.jobs_for_vendor_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideJobsForVendorModal=false;
            this.jobsForVendorModal.show();
          }
          if(res['state']==='hidden'){
            this.hideJobsForVendorModal=true;
            this.jobsForVendorModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.show_appointment_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideShowAppointmentModal=false;
            this.showAppointmentModal.show();
          }
          if(res['state']==='hidden'){
            this.hideShowAppointmentModal=true;
            this.showAppointmentModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.notification_alert_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideNotificationAlertModal=false;
            this.notificationAlertModal.show();
          }
          if(res['state']==='hidden'){
            this.hideNotificationAlertModal=true;
            this.notificationAlertModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.candidate_collaborators_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideCandidateCollaboratorsModal=false;
            this.candidateCollaboratorsModal.show();
          }
          if(res['state']==='hidden'){
            this.hideCandidateCollaboratorsModal=true;
            this.candidateCollaboratorsModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.saved_search_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideSavedSearchModal=false;
            this.savedSearchModal.show();
          }
          if(res['state']==='hidden'){
            this.hideSavedSearchModal=true;
            this.savedSearchModal.hide();
          }
          this.params = res["params"]
        }
    );
    this.modal.spinner_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hidespinnerModal=false;
            this.spinnerModal.show();
          }
          if(res['state']==='hidden'){
            this.hidespinnerModal=true;
            this.spinnerModal.hide();
          }
          this.params = res["params"]
        }
    );
    this.modal.top_up_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideTopUpModal=false;
            this.topUpModal.show();
          }
          if(res['state']==='hidden'){
            this.hideTopUpModal=true;
            this.topUpModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.custom_tracker_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideCustomTrackerModal=false;
            this.customTrackerModal.show();
          }
          if(res['state']==='hidden'){
            this.hideCustomTrackerModal=true;
            this.customTrackerModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.standardized_template_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideStandardizedTemplateModal=false;
            this.standardizedTemplateModal.show();
          }
          if(res['state']==='hidden'){
            this.hideStandardizedTemplateModal=true;
            this.standardizedTemplateModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.upgrade_plan_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideUpgradePlanModal=false;
            this.upgradePlanModal.show();
          }
          if(res['state']==='hidden'){
            this.hideUpgradePlanModal=true;
            this.upgradePlanModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.edit_banner_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideEditBannerModal=false;
            this.editBannerModal.show();
          }
          if(res['state']==='hidden'){
            this.hideEditBannerModal=true;
            this.editBannerModal.hide();
          }
          this.params = res["params"]
        }
    )
        this.modal.edit_logo_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideEditLogoModal=false;
            this.editLogoModal.show();
          }
          if(res['state']==='hidden'){
            this.hideEditLogoModal=true;
            this.editLogoModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.edit_about_company_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideEditAboutCompanyModal=false;
            this.editAboutCompanyModal.show();
          }
          if(res['state']==='hidden'){
            this.hideEditAboutCompanyModal=true;
            this.editAboutCompanyModal.hide();
          }
          this.params = res["params"]
        }
    )

    this.modal.career_settings_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideCareerSettingsModal=false;
            this.careerSettingsModal.show();
          }
          if(res['state']==='hidden'){
            this.hideCareerSettingsModal=true;
            this.careerSettingsModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.add_jobs_to_career_page_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideAddJobsToCareerPageModal=false;
            this.addJobsToCareerPageModal.show();
          }
          if(res['state']==='hidden'){
            this.hideAddJobsToCareerPageModal=true;
            this.addJobsToCareerPageModal.hide();
          }
          this.params = res["params"]
        }
    )

    this.modal.tobu_drive_stats_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideTobuDriveStatsModal=false;
            this.tobuDriveStatsModal.show();
          }
          if(res['state']==='hidden'){
            this.hideTobuDriveStatsModal=true;
            this.tobuDriveStatsModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.auto_forwarding_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideAutoForwardingModal=false;
            this.autoForwardingModal.show();
          }
          if(res['state']==='hidden'){
            this.hideAutoForwardingModal=true;
            this.autoForwardingModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.admin_update_pricing_plan_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideAdminUpdatePricingPlanModal=false;
            this.adminUpdatePricingPlanModal.show();
          }
          if(res['state']==='hidden'){
            this.hideAdminUpdatePricingPlanModal=true;
            this.adminUpdatePricingPlanModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.create_tag_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideCreateTagModal=false;
            this.createTagModal.show();
          }
          if(res['state']==='hidden'){
            this.hideCreateTagModal=true;
            this.createTagModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.help_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideHelpModal=false;
            this.helpModal.show();
          }
          if(res['state']==='hidden'){
            this.hideHelpModal=true;
            this.helpModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.auto_response_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideAutoResponseModal=false;
            this.autoResponseModal.show();
          }
          if(res['state']==='hidden'){
            this.hideAutoResponseModal=true;
            this.autoResponseModal.hide();
          }
          this.params = res["params"]
        }
    )

    this.modal.export_appointments_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideExportAppointmentsModal=false;
            this.exportAppointmentsModal.show();
          }
          if(res['state']==='hidden'){
            this.hideExportAppointmentsModal=true;
            this.exportAppointmentsModal.hide();
          }
          this.params = res["params"]
        }
    )

    this.modal.lead_card$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideLeadCardModal=false;
            this.leadCardModal.show();
          }
          if(res['state']==='hidden'){
            this.hideLeadCardModal=true;
            this.leadCardModal.hide();
          }
          this.params = res["params"]
        }
    )

    this.modal.access_levels$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideAccessLevelsModal=false;
            this.accessLevelsModal.show();
          }
          if(res['state']==='hidden'){
            this.hideAccessLevelsModal=true;
            this.accessLevelsModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.edit_stages_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideEditStagesModal=false;
            this.editStagesModal.show();
          }
          if(res['state']==='hidden'){
            this.hideEditStagesModal=true;
            this.editStagesModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.disabled_user_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideDisabledUserModal=false;
            this.disabledUserModal.show();
          }
          if(res['state']==='hidden'){
            this.hideDisabledUserModal=true;
            this.disabledUserModal.hide();
          }
          this.params = res["params"]
        }
    )
    
    this.modal.customize_column_order_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideCustomizeColumnOrderModal=false;
            this.customizeColumnOrderModal.show();
          }
          if(res['state']==='hidden'){
            this.hideCustomizeColumnOrderModal=true;
            this.customizeColumnOrderModal.hide();
          }
          this.params = res["params"]
        }
    )
    // this.modal.job_application_modal_selected$.subscribe(
    //   res=>
    //     {
    //       // console.log(res);
    //       if(res['state']==='displayed'){
    //         this.hideJobApplicationModal=false;
    //         this.jobApplicationModal.show();
    //       }
    //       if(res['state']==='hidden'){
    //         this.hideJobApplicationModal=true;
    //         this.jobApplicationModal.hide();
    //       }
    //       this.params = res["params"]
    //     }
    // )
  	
  	//this.hideSmallModal = false;
  }
  ngOnDestroy(){
    if(this.shared.isBrowser()){
    this.subscription.unsubscribe;
    this.subscription2.unsubscribe;
    this.subscription3.unsubscribe;
    this.subscription4.unsubscribe;
    this.subscription5.unsubscribe;
    this.sub3.unsubscribe;
    }
  }

  backClicked() {
    //this._location.back();
    this.hideCandidateModal=!this.hideCandidateModal
  }

  navigate_to(_link:string){
    // var params1 = this.candidate.get_params_from_url(this.router.url);
    // var params2 = this.job.get_params_from_url(this.router.url);
    // console.warn(params1);
    // console.warn(params2);
    if(this.router.url.includes('inbox') && this.router.url.includes('database')){
      // console.log('checkpoint 1');
      this.router.navigate(['/candidates/inbox/view/database', this.page_params['search_result_id'], _link ], { queryParamsHandling: "preserve" });
    }
    else if(this.router.url.includes('inbox') && this.router.url.includes('email')){
      // console.log('checkpoint 2');
      this.router.navigate(['/candidates/inbox/view/email', this.page_params['resume_email_id'], _link ], { queryParamsHandling: "preserve" });
    }
    else{
      // console.log('checkpoint 3');
      this.router.navigate(['/jobs/candidates', this.page_params['job_role_id'], 'listview', this.page_params['resume_report_id'], _link ], { queryParamsHandling: "preserve" });
    }
    this.navbar.set_current_page(_link, {});
  }
  email_candidate_modal(){
    var params1 = this.candidate.get_params_from_url(this.router.url);
    // this.modal.load_modal('email', 'medium', params1);
    this.modal.load_modal_by_name('email', params1)
   
  }
  transfer_candidate_modal(){
    var params1 = this.candidate.get_params_from_url(this.router.url);
    // this.modal.load_modal('transfer_to_job', 'small', params1);
    this.modal.load_modal_by_name('transfer_to_job', params1)
  }
  share_candidate_modal(){
    var params1 = this.candidate.get_params_from_url(this.router.url);
    // this.modal.load_modal('share_candidate', 'medium', params1);
    this.modal.load_modal_by_name('share_candidate', params1)
  }
  is_job_section(){
    return !this.router.url.includes('inbox')
  }
  load_configure_email(){
    this.subscription5 = this.authService.get('/api/has_uploaded_resumes').subscribe(
      res => {
        console.log(res)
        this.is_account_active= res['is_account_active']
        this.is_recruiter_account = res['is_recruiter_account']
        this.has_ip_restriction = res['has_ip_restriction']
        this.is_resume_view_default = res['is_resume_view_default']
        if(!res['is_account_active'] || res['has_ip_restriction']){
          this.modal.load_modal_by_name('disabled_user', {})
          
        }
        else if (!res['has_uploaded_resumes']){
          // this.modal.load_modal('configure_email', 'big', {})
          this.modal.load_modal_by_name('configure_email', {})
        }
      }
    )
  }
  hideModal(name) {
    // this.modal.hide_modal(type);
    this.modal.hide_modal_by_name(name)
    // console.warn('hiding')
  }

  hide_candidate(_type){
    // this.hideModal('candidate');
    if (_type==='resume_reports'){
      this.router.navigate(['/jobs/candidates', this.page_params['job_role_id'] ], {queryParams: {'open_result': null}, queryParamsHandling: "merge" })
      // this.modal.hide_modal_by_name('candidate')
      // this.navbar.set_current_page('jobs', {})
    }
    else if(_type==='inbox'){
      this.router.navigate(['/candidates/inbox/view'], {queryParams: {'open_result': null}, queryParamsHandling: "merge" })
      // this.modal.hide_modal_by_name('candidate')
      // this.navbar.set_current_page('inbox', {})
    }

  }
  close_send_only_email(){
    
    this.authService.post('/api/set_send_only_email', {send_only: 'false'}).subscribe(
      res=>console.log(res),
      err=> console.log(err)
    )
    this.hideModal('configure_send_only_email')
  }
  close_invitations(){
    this.modal.hide_modal_by_name('invite_users');
    this.router.navigate(['settings/settings/manageusers'], { queryParamsHandling: "preserve" });
  }
  isLoggedin(){
    return this.authService.isLoggedIn();
  }
  signout(){
    this.authService.logOut();
    this.router.navigate(['/account/login']);
  }
  open_video_help(){
    // this.modal.load_modal_by_name('help_video', {})
    if(this.router.url.includes('invitations'))
    {
      this.router.navigate(['/help-videos/manage_invites']);
      this.modal.hide_modal_by_name('invite_users');
      }
    else{
      this.router.navigate(['/help-videos/candidate_card_basics']);
      this.modal.hide_modal_by_name('candidate');
    }
  }
  open_candidate(id){
    console.log(id)
    if(this.router.url.includes('candidates/inbox')){
      // this.shared.refresh('/candidates/inbox/view/database/'+ id.toString() +'/analytics');
      if(this.is_resume_view_default)
        {this.router.navigate(['/candidates/inbox/view/database/'+ id.toString() +'/resume'],{ queryParams: { open_result: id.toString() }, queryParamsHandling: "merge"  })}
      else
        {this.router.navigate(['/candidates/inbox/view/database/'+ id.toString() +'/analytics'],{ queryParams: { open_result: id.toString() }, queryParamsHandling: "merge"  })}
      // this.router.navigate(['/candidates/inbox/view/database/'+ id.toString() +'/analytics'], {queryParamsHandling: "preserve"} )
    }
    else if(this.router.url.includes('jobs/candidates')){
      console.log(this.page_params['job_role_id'])
      console.log(id)
      // this.shared.refresh('/jobs/candidates/'+ this.page_params['job_role_id'].toString() + '/listview/'+ id.toString() +'/analytics')
      if(this.is_resume_view_default)
        {this.router.navigate(['/jobs/candidates/'+ this.page_params['job_role_id'].toString() + '/listview/' + id.toString() +'/resume'],{ queryParams: { open_result: id.toString() }, queryParamsHandling: "merge"  })}
      else
        {this.router.navigate(['/jobs/candidates/'+ this.page_params['job_role_id'].toString() + '/listview/' + id.toString() +'/analytics'],{ queryParams: { open_result: id.toString() }, queryParamsHandling: "merge"  })}

    }
  }
}
