const Home = {
    text: 'Home',
    link: '/home',
    icon: 'icon-home'
};

const Candidates = {
    text: 'Candidates',
    link: '/candidates/load_candidates',
    icon: 'icon-people',
    // alert: 'PRO'
    // submenu: [
    //     {
    //         text: 'Resumes to Excel',
    //         link: '/candidates/resumeparser',
    //         //icon: 'fa fa-file-excel-o'
    //     },
    //     {
    //         text: 'Search Database',
    //         link: '/candidates/searchdatabase',
    //         //icon: 'fa fa-search'
    //     },
    //     {
    //         text: 'Saved Searches',
    //         link: '/candidates/savedsearches',
    //         //icon: 'fa fa-bookmark'
    //     },
    //     {
    //         text: 'Upload to Database',
    //         link: '/candidates/uploadresumes',
    //         //icon: 'fa fa-upload'
    //     },
    //     {
    //         text: 'Tags',
    //         link: '/candidates/tags',
    //         //icon: 'fa fa-folder-open'
    //     }
    // ]
};
const Jobs = {
    text: 'Jobs',
    // link: '/jobs/load_job',
    link: '/jobs/list',
    icon: 'icon-briefcase',
    // submenu: [
    //     {
    //         text: 'Open Positions',
    //         link: '/jobs/openpositions',
    //         //icon: 'fa fa-circle-o'
    //     },
    //     {
    //         text: 'Closed Positions',
    //         link: '/jobs/closedpositions',
    //         //icon: 'fa fa-times-circle-o'
    //     },
    //     {
    //         text: 'New Job Opening',
    //         link: '/jobs/newjobopening',
    //         //icon: 'fa fa-plus'
    //     }
    // ]
};    

const Invite = {
    text: 'Invite Users',
    link: '/settings/manageusers/invitations',
    icon: 'icon-share'
};
// const Emails = {
//     text: 'Email Inbox',
//     link: '/emails/inbox',
//     icon: 'icon-envelope'
// };
const Appointments = {
    text: 'Appointments',
    link: '/calendar/appointments',
    icon: 'icon-calendar',
    // alert: 'PRO'
};
const Parser = {
    text: 'Extracts',
    link: '/extracts',
    icon: 'icon-list'
};

const Reports = {
    text: 'Reports',
    link: '/reports',
    icon: 'icon-graph',
    // submenu: [
    //     {
    //         text: 'Job Analytics',
    //         link: '/reports/jobanalytics',
    //         //icon: 'fa fa-briefcase'
    //     },
    //     {
    //         text: 'Recruiter Analytics',
    //         link: '/reports/recruiteranalytics',
    //         //icon: 'fa fa-users'
    //     },
    //     {
    //         text: 'Sourcing Report',
    //         link: '/reports/sourcingreport',
    //         //icon: 'fa fa-share-square-o'
    //     }
    // ]
};
const Download = {
    text: 'Sync Desktop',
    link: '/download',
    icon: 'icon-cloud-upload',
    // alert: 'PRO'
};
const EmailCampaign = {
    text: 'Bulk Emails',
    link: '/email-campaign/templates',
    icon: 'icon-envelope',
    // alert: 'PRO'
};
const Career = {
    text: 'Career Page',
    link: '/career-page',
    icon: 'icon-globe',
    // alert: 'PRO'
    // submenu: [
    //     {
    //         text: 'Configure Email',
    //         link: '/settings/configureemail',
    //         //icon: 'fa fa-envelope'
    //     },
    //     {
    //         text: 'Manage Users',
    //         link: '/settings/manageusers',
    //         //icon: 'fa fa-user'
    //     },
    //     {
    //         text: 'Access Levels',
    //         link: '/settings/accesslevels/',
    //         //icon: 'fa fa-sitemap'
    //     },
    //     {
    //         text: 'Custom Tracker',
    //         link: '/settings/customtracker/',
    //         //icon: 'fa fa-line-chart'
    //     },
    //     {
    //         text: 'Billing',
    //         link: '/settings/billing/',
    //         //icon: 'fa fa-inr'
    //     },
    //     {
    //         text: 'IP Address Restrictions',
    //         link: '/settings/iprestrictions/',
    //         //icon: 'fa fa-lock'
    //     }
    // ]
};
const Settings = {
    text: 'Settings',
    link: '/settings',
    icon: 'icon-settings',
    // submenu: [
    //     {
    //         text: 'Configure Email',
    //         link: '/settings/configureemail',
    //         //icon: 'fa fa-envelope'
    //     },
    //     {
    //         text: 'Manage Users',
    //         link: '/settings/manageusers',
    //         //icon: 'fa fa-user'
    //     },
    //     {
    //         text: 'Access Levels',
    //         link: '/settings/accesslevels/',
    //         //icon: 'fa fa-sitemap'
    //     },
    //     {
    //         text: 'Custom Tracker',
    //         link: '/settings/customtracker/',
    //         //icon: 'fa fa-line-chart'
    //     },
    //     {
    //         text: 'Billing',
    //         link: '/settings/billing/',
    //         //icon: 'fa fa-inr'
    //     },
    //     {
    //         text: 'IP Address Restrictions',
    //         link: '/settings/iprestrictions/',
    //         //icon: 'fa fa-lock'
    //     }
    // ]
};
const Admin = {
    text: 'Admin',
    link: '/admin',
    icon: 'icon-earphones-alt',
    // submenu: [
    //     {
    //         text: 'Admin Companies',
    //         link: '/admin/admincompanies',
    //         //icon: 'fa fa-building'
    //     },
    //     {
    //         text: 'Admin Customers',
    //         link: '/admin/admincustomers',
    //         //icon: 'fa fa-building'
    //     },
    //     {
    //         text: 'Admin Leads',
    //         link: '/admin/adminleads',
    //         //icon: 'fa fa-envelope'
    //     },
    //     {
    //         text: 'Admin Blog',
    //         link: '/admin/adminblog',
    //         //icon: 'fa fa-book'
    //     },
    //     {
    //         text: 'Admin Domains',
    //         link: '/admin/admindomains',
    //         //icon: 'fa fa-globe'
    //     }
    // ]
};
export const menu = [
    Candidates,
    Parser,
    Jobs,
    Invite,
    // Emails,
    Appointments,
    
    Reports,
    Download,
    EmailCampaign,
    Career,
    Settings,
    // Admin
];
export const menu_non_ats = [
    Candidates,
    Parser,
    // Jobs,
    Invite,
    // Emails,
    // Appointments,
    
    Reports,
    Download,
    EmailCampaign,
    // Career,
    Settings,
    // Admin
];
