import { NgModule } from '@angular/core';
import { DndModule } from 'ng2-dnd';

// import { CommonModule } from '@angular/common';
import { AnalyticsComponent } from './candidate/analytics/analytics.component';
import { ViewresumeComponent } from './candidate/viewresume/viewresume.component';
import { CommentsComponent } from './candidate/comments/comments.component';
import { EmailhistoryComponent } from './candidate/emailhistory/emailhistory.component';
import { ActivitylogComponent } from './candidate/activitylog/activitylog.component'
import { Routes, RouterModule } from '@angular/router';
// import { ModalsComponent } from '../../layout/modals/modals.component';
import { SharedModule } from '../../shared/shared.module';
import { DetailsComponent } from './candidate/details/details.component';
import { TagInputModule } from 'ngx-chips';
import { LayoutModule } from '../../layout/layout.module'

// import { SafePipe } from './safe.pipe';
import { EmailComponent } from './email/email.component';
import { SearchComponent } from './search/search.component';
import { CreateJobComponent } from './create-job/create-job.component';
import { AddTagsComponent } from './add-tags/add-tags.component';
import { DeleteCandidatesComponent } from './delete-candidates/delete-candidates.component';
import { JobDescriptionComponent } from './job-description/job-description.component';
import { SourceFromVendorComponent } from './source-from-vendor/source-from-vendor.component';
import { ManageCollaboratorsComponent } from './manage-collaborators/manage-collaborators.component';
import { KeywordRankComponent } from './keyword-rank/keyword-rank.component';
import { DownloadCsvComponent } from './download-csv/download-csv.component';
import { MassUpdateTrackingComponent } from './mass-update-tracking/mass-update-tracking.component';
import { UploadResumesComponent } from './upload-resumes/upload-resumes.component';
import { TransferToJobComponent } from './transfer-to-job/transfer-to-job.component';
import { ShareCandidateComponent } from './share-candidate/share-candidate.component';
import { ModalsService} from '../../layout/modals/modals.service'
import { NavbarService} from '../../layout/header/navbar.service';
import { JobsForVendorComponent } from './jobs-for-vendor/jobs-for-vendor.component';
import { InviteUsersComponent } from './invite-users/invite-users.component';
import { ShowAppointmentComponent } from './show-appointment/show-appointment.component';
import { InterviewsComponent } from './candidate/interviews/interviews.component';
import { FileUploadModule} from 'ng2-file-upload';
// import { ToasterService } from 'angular2-toaster/angular2-toaster';
import { ToastrService } from 'ngx-toastr';
import { NgxSelectModule } from 'ngx-select-ex'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationAlertComponent } from './notification-alert/notification-alert.component';
import { CandidateCollaboratorsComponent } from './candidate-collaborators/candidate-collaborators.component';
import { ConfigureEmailComponent } from './configure-email/configure-email.component'; // <== add the imports!
import { SafePipe } from '../../core/shared/safe-pipe.service';
import { SavedSearchComponent } from './saved-search/saved-search.component';
import { TopUpComponent } from './top-up/top-up.component';
import { JobApplicationComponent } from './job-application/job-application.component';
import { FreshnessComponent } from './reports/freshness/freshness.component';
import { ReceivedOnComponent } from './reports/received-on/received-on.component';
import { SeniorityComponent } from './reports/seniority/seniority.component';
import { LocationComponent } from './reports/location/location.component';
import { JobTitlesComponent } from './reports/job-titles/job-titles.component';
import { SourcesComponent } from './reports/sources/sources.component';
import { ConfigureSendOnlyEmailComponent } from './configure-send-only-email/configure-send-only-email.component';
import { CreateClientComponent } from './create-client/create-client.component';
import { RecruiterStatsComponent } from './reports/recruiter-stats/recruiter-stats.component';
import { JobStatsComponent } from './reports/job-stats/job-stats.component';
import { JobTimeStatsComponent } from './reports/job-time-stats/job-time-stats.component';
import { EmailCampaignComponent } from './email-campaign/email-campaign.component';
import { CustomTrackerComponent } from './custom-tracker/custom-tracker.component';
import { PricingComponent } from './pricing/pricing.component';
import { ImageDrawingModule } from 'ngx-image-drawing';
import { EditLogoComponent } from './edit-logo/edit-logo.component';
import { EditBannerComponent } from './edit-banner/edit-banner.component';
import { EditAboutCompanyComponent } from './edit-about-company/edit-about-company.component';
import { ImageCropperModule } from 'ngx-img-cropper';
import { CareerPageSettingsComponent } from './career-page-settings/career-page-settings.component';
import { AddJobsToCareerPageComponent } from './add-jobs-to-career-page/add-jobs-to-career-page.component';
import { StandardizedTemplateComponent } from './standardized-template/standardized-template.component';
import { ExportStandardizedTemplateComponent } from './export-standardized-template/export-standardized-template.component';
import { TobuDriveStatsComponent } from './tobu-drive-stats/tobu-drive-stats.component';
// import { Ng2TableModule } from 'ng2-table/ng2-table';
import { AgGridModule } from 'ag-grid-angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AutoForwardingComponent } from './auto-forwarding/auto-forwarding.component';
import { AdminUpdatePricingPlanComponent } from './admin-update-pricing-plan/admin-update-pricing-plan.component';
import { CreateTagComponent } from './create-tag/create-tag.component';
import { HelpComponent } from './help/help.component';
import { DisplaySecurityInformationComponent } from './display-security-information/display-security-information.component';
import { CandidateAutoResponseComponent } from './candidate-auto-response/candidate-auto-response.component';
import { HelpVideoComponent } from './help-video/help-video.component';
import { ArrangeDemoFormComponent } from './arrange-demo-form/arrange-demo-form.component';
import { ExportAppointmentsComponent } from './export-appointments/export-appointments.component';
import { LeadCardComponent } from './lead-card/lead-card.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AccessLevelsComponent } from './access-levels/access-levels.component';
import { EditStagesComponent } from './edit-stages/edit-stages.component';
import { QuillModule } from 'ngx-quill'
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
// import {ImageResize} from 'quill-image-resize-module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyFieldStepper } from './arrange-demo-form/stepper.type';
import { MatStepperModule } from '@angular/material/stepper';
import { applyEmailValidation } from './arrange-demo-form/email.extension';
import { FaqsComponent } from './faqs/faqs.component';
import { ConfigureDesktopComponent } from './configure-desktop/configure-desktop.component';
import { GmailCallbackAddOnComponent } from './gmail-callback-add-on/gmail-callback-add-on.component';
import { GmailCallbackLoginComponent } from './gmail-callback-login/gmail-callback-login.component';
import { AuthorizeGmailAddOnComponent } from './authorize-gmail-add-on/authorize-gmail-add-on.component';
import { NgxDocViewerModule} from 'ngx-doc-viewer';
import { MicrosoftCallbackLoginComponent } from './microsoft-callback-login/microsoft-callback-login.component';
import { ColorPickerModule, ColorPickerService } from 'ngx-color-picker';
import { ManageTagsComponent } from './manage-tags/manage-tags.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CustomizeColumnOrderComponent } from './customize-column-order/customize-column-order.component';
import { AnalyticsFullComponent } from './candidate/analytics-full/analytics-full.component';
// import { NgxCaptchaModule } from 'ngx-captcha';
let imports = [
    SharedModule,
    TagInputModule,
    DndModule.forRoot(),
    NgxSelectModule,
    NgxDocViewerModule,
    FormsModule,                               // <========== Add this line!
    ReactiveFormsModule,
    ColorPickerModule,
    FileUploadModule,
    ImageDrawingModule,
    ImageCropperModule,
    // Ng2TableModule,
    AngularEditorModule,
    QuillModule.forRoot(),
    NgxSkeletonLoaderModule,
    FormlyBootstrapModule,
    NgxSliderModule,
    FormlyModule.forRoot({
      validationMessages: [
        { name: 'required', message: 'This field is required' },
      ],
      types: [
        { name: 'stepper', component: FormlyFieldStepper, wrappers: [] },

      ],
      extensions: [{ name: 'email', extension: { prePopulate: applyEmailValidation } }],
    }),
    MatStepperModule,
    // NgxCaptchaModule,
    // QuillModule.forRoot({
    //   customModules: [
    //   {
    //     implementation: ImageResize,
    //     path: 'modules/ImageResize',
    //   }],
    //   modules: {
    //     ImageResize: true
    //   },
    //   suppressGlobalRegisterWarning: true
    // })
        //CommonModule
    //RouterModule.forChild(routes)
    ]
// if(isPlatformBrowser){
//     imports.push(ImageDrawingModule);
// }

@NgModule({
  declarations: [
  AnalyticsComponent, 
  ViewresumeComponent, 
  CommentsComponent,
  EmailhistoryComponent, 
  ActivitylogComponent, 
  DetailsComponent,
  SafePipe,
  EmailComponent,
  SearchComponent,
  CreateJobComponent,
  AddTagsComponent,
  ManageTagsComponent,
  DeleteCandidatesComponent,
  JobDescriptionComponent,
  SourceFromVendorComponent,
  ManageCollaboratorsComponent,
  KeywordRankComponent,
  DownloadCsvComponent,
  MassUpdateTrackingComponent,
  UploadResumesComponent,
  TransferToJobComponent,
  ShareCandidateComponent,
  JobsForVendorComponent,
  InviteUsersComponent,
  ShowAppointmentComponent,
  InterviewsComponent,
  NotificationsComponent,
  NotificationAlertComponent,
  CandidateCollaboratorsComponent,
  ConfigureEmailComponent,
  SavedSearchComponent,
  TopUpComponent,
  JobApplicationComponent,
  FreshnessComponent,
  ReceivedOnComponent,
  SeniorityComponent,
  LocationComponent,
  JobTitlesComponent,
  SourcesComponent,
  ConfigureSendOnlyEmailComponent,
  CreateClientComponent,
  RecruiterStatsComponent,
  JobStatsComponent,
  JobTimeStatsComponent,
  EmailCampaignComponent,
  CustomTrackerComponent,
  PricingComponent,
  EditLogoComponent,
  EditBannerComponent,
  EditAboutCompanyComponent,
  CareerPageSettingsComponent,
  AddJobsToCareerPageComponent,
  StandardizedTemplateComponent,
  ExportStandardizedTemplateComponent,
  TobuDriveStatsComponent,
  AutoForwardingComponent,
  AdminUpdatePricingPlanComponent,
  CreateTagComponent,
  HelpComponent,
  DisplaySecurityInformationComponent,
  CandidateAutoResponseComponent,
  HelpVideoComponent,
  ArrangeDemoFormComponent,
  ExportAppointmentsComponent,
  CustomizeColumnOrderComponent,
  LeadCardComponent,
  AccessLevelsComponent,
  EditStagesComponent,
  FormlyFieldStepper,
  FaqsComponent,
  ConfigureDesktopComponent,
  GmailCallbackAddOnComponent,
  GmailCallbackLoginComponent,
  AuthorizeGmailAddOnComponent,
  MicrosoftCallbackLoginComponent,
  AnalyticsFullComponent,
  
  ],
  imports: imports
  //[
    // SharedModule,
    // TagInputModule,
    // DndModule.forRoot(),
    // NgxSelectModule,
    // FormsModule,                               // <========== Add this line!
    // ReactiveFormsModule,
    // FileUploadModule,
    // // ImageDrawingModule,
    // ImageCropperModule,
    // // Ng2TableModule,
    // AngularEditorModule,
    // QuillModule.forRoot()
    //     //CommonModule
    // //RouterModule.forChild(routes)
  //]
  ,providers: [
        // ModalsService, NavbarService
        ToastrService,
        ColorPickerService
    ],
  exports: [
    // RouterModule,
    AnalyticsComponent,
    ViewresumeComponent,
    CommentsComponent,
    EmailhistoryComponent,
    ActivitylogComponent,
    DetailsComponent,
    EmailComponent,
    SearchComponent,
    CreateJobComponent,
    AddTagsComponent,
    ManageTagsComponent,
    DeleteCandidatesComponent,
    JobDescriptionComponent,
    SourceFromVendorComponent,
    ManageCollaboratorsComponent,
    KeywordRankComponent,
    DownloadCsvComponent,
    MassUpdateTrackingComponent,
    UploadResumesComponent,
    TransferToJobComponent,
    ShareCandidateComponent,
    JobsForVendorComponent,
    InviteUsersComponent,
    ShowAppointmentComponent,
    InterviewsComponent,
    NotificationsComponent,
    NotificationAlertComponent,
    CandidateCollaboratorsComponent,
    ConfigureEmailComponent,
    SavedSearchComponent,
    TopUpComponent,
    JobApplicationComponent,
    FreshnessComponent,
    ReceivedOnComponent,
    SeniorityComponent,
    LocationComponent,
    JobTitlesComponent,
    SourcesComponent,
    ConfigureSendOnlyEmailComponent,
    CreateClientComponent,
    RecruiterStatsComponent,
    JobStatsComponent,
    JobTimeStatsComponent,
    EmailCampaignComponent,
    CustomTrackerComponent,
    PricingComponent,
    EditLogoComponent,
    EditBannerComponent,
    EditAboutCompanyComponent,
    CareerPageSettingsComponent,
    AddJobsToCareerPageComponent,
    StandardizedTemplateComponent,
    ExportStandardizedTemplateComponent,
    TobuDriveStatsComponent,
    AutoForwardingComponent,
    AdminUpdatePricingPlanComponent,
    CreateTagComponent,
    HelpComponent,
    DisplaySecurityInformationComponent,
    CandidateAutoResponseComponent,
    HelpVideoComponent,
    ArrangeDemoFormComponent,
    ExportAppointmentsComponent,
    LeadCardComponent,
    AccessLevelsComponent,
    EditStagesComponent,
    FaqsComponent,
    ConfigureDesktopComponent,
    CustomizeColumnOrderComponent,
    AnalyticsFullComponent
  ]
})
export class SharedroutesModule { }
